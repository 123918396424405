import { useState, useRef } from "react";
import { getRoadmapLine } from "../../../helpers";
import styles from "./index.module.scss";
import { RoadIdType, RoadMapLineStatusType } from "../../../constants";

interface Props {
  roadmapId?: RoadIdType;
  status?: RoadMapLineStatusType;
}

export const RoadmapLine = ({ roadmapId = "1", status = "locked" }: Props) => {
  const imgRef = useRef(null);
  const [imageWidth, setImageWidth] = useState<number | undefined>();
  const imageSrc = getRoadmapLine(roadmapId, status);

  if (!imageSrc) return null;

  return (
    <div className={styles.roadmapLine}>
      <img
        ref={imgRef}
        src={imageSrc}
        className={styles.roadmapLine__img}
        style={{
          width: imageWidth ? `${(imageWidth / 100) * 100}%` : undefined,
        }}
        alt=""
        onLoad={() => {
          if (imgRef?.current) {
            const imgElem = imgRef?.current as HTMLImageElement;
            setImageWidth(imgElem?.naturalWidth);
          }
        }}
      />
    </div>
  );
};

export default RoadmapLine;
