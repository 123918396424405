/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITAL_VIDEO_INFOS, RoadMapStatusType } from "../../constants";

export interface VideoState {
  id: string;
  state: RoadMapStatusType;
  next?: string[];
  parents?: string[];
  played?: boolean;
  isEnding?: boolean;
}

const initialVideoState: VideoState[] = INITAL_VIDEO_INFOS.map((v) => ({
  id: v.id,
  state: "locked",
  next: v.next,
  parents: v.parents,
  played: false,
  isEnding: !!v?.ending,
}));

export const videoSlice = createSlice({
  name: "videos",
  initialState: initialVideoState,
  reducers: {
    reset: (state) => {
      state.forEach((v) => {
        v.state = "ignore";
        v.played = false;
      });
    },
    playVideo: (state, action: PayloadAction<string>) => {
      const video = state.find((v) => v.id === action.payload);
      if (video) {
        video.state = "playing";
      }
      state.forEach((v) => {
        if (v.id !== action.payload) {
          if (video?.parents?.includes(v.id)) {
            // played and current route
            v.state = "played-current-path";
          } else if (v?.played) {
            // current route
            v.state = "played";
          } else if (v?.state !== "locked") {
            // other route
            v.state = "ignore";
          }
        }
      });
    },
    videoPlayed: (state, action: PayloadAction<string>) => {
      const video = state.find((v) => v.id === action.payload);
      if (video) {
        // video.state = "played-current-path";
        video.played = true;
        video.next?.forEach((nextId) => {
          const nextVideo = state.find((v) => v.id === nextId);
          if (nextVideo && nextVideo.state === "locked") {
            nextVideo.state = "ignore";
          }
        });
      }
      state.forEach((v) => {
        if (v.id !== action.payload && v?.state !== "playing") {
          if (video?.parents?.includes(v.id)) {
            // played and current route
            v.state = "played-current-path";
          } else if (v?.played) {
            // current route
            v.state = "played";
          } else if (v?.state !== "locked") {
            // other route
            v.state = "ignore";
          }
        }
      });
    },
  },
});

export const { playVideo, videoPlayed, reset } = videoSlice.actions;
export default videoSlice.reducer;
