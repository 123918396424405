import clsx from "clsx";
import styles from "./index.module.scss";

interface Props {
  animated?: boolean;
  finished?: boolean;
  slideIn?: boolean;
  imgSrc?: { leftImages?: string[]; rightImages?: string[] };
}

export const LandingBg = ({
  animated,
  slideIn = true,
  finished,
  imgSrc,
}: Props) => {
  const leftImages = imgSrc?.leftImages;
  const rightImages = imgSrc?.rightImages;
  if (finished) return null;
  return (
    <div
      className={clsx(
        styles.landingBg,
        animated && styles.mod__animated,
        leftImages && rightImages && styles.mod__fadingImages,
        slideIn && styles.mod__slideIn,
        !imgSrc && styles.mod__introImg,
      )}
    >
      <div className={styles.landingBg__left}>
        <div className={styles.landingBg__bgImg}>
          <div
            className={styles.landingBg__bgImgSrc}
            style={{
              backgroundImage: leftImages ? `url(${leftImages[0]})` : undefined,
            }}
          >
            {/* {leftImages && (
              <WavyImage
                animated={animated}
                imgSrcs={leftImages}
                direction="left"
              />
            )} */}
          </div>
        </div>
      </div>
      <div className={styles.landingBg__right}>
        <div className={styles.landingBg__bgImg}>
          <div
            className={styles.landingBg__bgImgSrc}
            style={{
              backgroundImage: rightImages
                ? `url(${rightImages[0]})`
                : undefined,
            }}
          >
            {/* {rightImages && (
              <WavyImage
                animated={animated}
                imgSrcs={rightImages}
                direction="right"
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBg;
