import React from "react";
import styles from "./index.module.scss";

export const EasterEggIndicator = () => {
  return (
    <div className={styles.easterEggIndicator}>
      <img
        className={styles.easterEggIndicator__image1}
        src="/images/easter-egg/indicator/img-indicator-1.svg"
        alt=""
      />
      <img
        className={styles.easterEggIndicator__image2}
        src="/images/easter-egg/indicator/img-indicator-2.svg"
        alt=""
      />
      <img
        className={styles.easterEggIndicator__image3}
        src="/images/easter-egg/indicator/img-indicator-3.svg"
        alt=""
      />
    </div>
  );
};

export default EasterEggIndicator;
