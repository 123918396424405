import React, { useEffect } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import EndingPanel from "../EndingPanel";
import EndingList from "../EndingList";
import EndingBg from "../EndingBg";

interface Props {
  id?: string;
  show: boolean;
  openRoadmap?: () => void;
}

export const Ending = React.memo(
  ({ id = "11", show, openRoadmap = () => null }: Props) => {
    const [panelAnimated, setPanelAnimated] = React.useState(false);

    useEffect(() => {
      if (show) {
        setTimeout(() => {
          setPanelAnimated(true);
        }, 2000);
      } else {
        setPanelAnimated(false);
      }
    }, [show]);
    return (
      <div
        className={clsx(
          styles.ending,
          show && styles.mod__show,
          panelAnimated && styles.mod__animated,
          id && styles[`mod__ending${id}`],
        )}
      >
        {show && <EndingBg id={id} show={show} />}
        <div className={clsx(styles.ending__body)}>
          <div className={clsx(styles.ending__kv)} />
          <div className={clsx(styles.ending__panel)}>
            <EndingPanel
              id={id}
              animated={panelAnimated}
              openRoadmap={openRoadmap}
            />
          </div>
        </div>
        <div className={clsx(styles.ending__endingList)}>
          <EndingList />
        </div>
      </div>
    );
  },
);

export default Ending;
