import clsx from "clsx";
import styles from "./index.module.scss";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: string;
  iconStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

export const ControlButton = ({
  children,
  icon,
  iconStyle,
  ...props
}: Props) => {
  const isIconButton = icon && !children;
  return (
    <button
      {...props}
      className={clsx(
        styles.controlButton,
        isIconButton && styles.mod__iconBtn,
        props.className,
      )}
    >
      {icon && (
        <img
          className={styles.controlButton__icon}
          src={icon}
          alt=""
          style={iconStyle}
        />
      )}
      {children && (
        <div className={styles.controlButton__inner}>{children}</div>
      )}
    </button>
  );
};

export default ControlButton;
