/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from "react";

type UseImagePreloadResult = {
  imageLoaded: boolean;
  allReady: boolean;
  progress: number;
};

function useImagePreload(
  images: string[],
  finishDelay: number,
): UseImagePreloadResult {
  const [progress, setProgress] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [allReady, setAllReady] = useState(false);

  useEffect(() => {
    let loaded = 0;
    const imgElements: HTMLImageElement[] = [];

    images.forEach((src, i) => {
      imgElements[i] = new Image();
      imgElements[i].src = src;
      imgElements[i].onload = () => {
        loaded += 1;
        setProgress(Math.floor((loaded / images.length) * 100));
        if (loaded === images.length) {
          setImageLoaded(true);
        }
      };
    });

    // Clean up function to abort loading images if the component unmounts
    return () => {
      imgElements.forEach((img) => {
        img.onload = null;
        img.src = "";
      });
    };
  }, [images]);

  useEffect(() => {
    if (imageLoaded) {
      const timeoutId = setTimeout(() => {
        setAllReady(true);
      }, finishDelay);

      // Clean up function to clear the timeout if the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [imageLoaded, finishDelay]);

  return { imageLoaded, allReady, progress };
}

export default useImagePreload;
