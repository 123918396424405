import { useSelector } from "react-redux";
import { Fragment } from "react";
import styles from "./index.module.scss";
import ENDING_INFO from "../../../constants";
import { VideoState } from "../../_Video/videoDuck";

interface EndingListItemProps {
  unlocked?: boolean;
}

export const EndingListItem = ({ unlocked }: EndingListItemProps) => {
  return (
    <div className={styles.roadmapEnding}>
      <img
        src={
          unlocked
            ? "/images/icons/icon-tick.svg"
            : "/images/icons/icon-lock.svg"
        }
        className={styles.roadmapEnding__icon}
        alt=""
      />
    </div>
  );
};

export const EndingList = () => {
  const videoStatus = useSelector((state: any) => state.videos);
  const finishedEndings = videoStatus
    .filter((v: VideoState) => v.isEnding && v.played)
    .map((v: VideoState) => v.id);
  return (
    <div className={styles.endingList}>
      <h3 className={styles.endingList__subTitle}>
        已完成結局 <em>{`${finishedEndings.length}/${ENDING_INFO.length}`}</em>
      </h3>
      <div className={styles.endingList__endings}>
        {ENDING_INFO.map((ending) => {
          const unlocked = finishedEndings.includes(ending.id);
          return (
            <Fragment key={ending.id}>
              <EndingListItem unlocked={unlocked} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default EndingList;
