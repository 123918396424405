import clsx from "clsx";
import styles from "./index.module.scss";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  animated?: boolean;
  // TODO: make icons, text, etc. to be passed in as props
}

const IMG_BTN_BG = "/images/icons/icon-start-button/img-start-button-bg.svg";
const IMG_ICON_BG =
  "/images/icons/icon-start-button/img-start-button-icon-bg.svg";
const IMG_ICON = "/images/icons/icon-start-button/img-start-button-icon.svg";
const IMG_TXT = "/images/icons/icon-start-button/img-start-button-text.svg";
const IMG_REF = "/images/landing/img-landing-btn.png";

export const AnimatedButton = ({ children, animated, ...props }: Props) => {
  return (
    <button
      {...props}
      className={clsx(
        styles.animatedButton,
        animated && styles.mod__animated,
        props.className,
      )}
    >
      <div className={clsx(styles.animatedButton__body)}>
        <div className={clsx(styles.animatedButton__icon)}>
          <img
            className={styles.animatedButton__iconBg}
            src={IMG_ICON_BG}
            alt=""
          />
          <img
            className={styles.animatedButton__iconPlay}
            src={IMG_ICON}
            alt=""
          />
        </div>
        <div className={clsx(styles.animatedButton__textBody)}>
          <div className={clsx(styles.animatedButton__textBodyInner)}>
            <img
              className={styles.animatedButton__textBg}
              src={IMG_BTN_BG}
              alt=""
            />
            <img className={styles.animatedButton__text} src={IMG_TXT} alt="" />
            {children && (
              <div className={styles.animatedButton__chidren}>{children}</div>
            )}
          </div>
        </div>
      </div>
      <img className={styles.animatedButton__ref} src={IMG_REF} alt="" />
    </button>
  );
};

export default AnimatedButton;
