/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { useState } from "react";

type UseVideoTypes = {
  ready: boolean;
  playing: boolean;
  played: number;
  seeking: boolean;
  playedSeconds: number;
  handleSeekMouseDown: () => void;
  seekTo: (value: number) => void;
  playVideo: () => void;
  pauseVideo: () => void;
  onClickPlay: () => void;
  onVideoReady: () => void;
  handleSeekChange: (e: any) => void;
  handleSeekMouseUp: (e: any) => void;
  handleProgress: (progress: any) => void;
  initVideo: () => void;
};

function useVideo(playerRef: any): UseVideoTypes {
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const seekTo = (value: number) => {
    const player = playerRef?.current as any;
    player?.seekTo(value);
  };

  const playVideo = () => {
    setPlaying(true);
  };

  const pauseVideo = () => {
    setPlaying(false);
  };

  const onVideoReady = () => {
    setReady(true);
  };

  const onClickPlay = () => {
    if (playing) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const initVideo = () => {
    setReady(false);
    setPlaying(false);
    setPlayed(0);
    setSeeking(false);
    setPlayedSeconds(0);
  };

  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e.target.value));
    seekTo(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);
    seekTo(parseFloat(e.target.value));
  };
  const handleProgress = (progress: any) => {
    // The playedSeconds property contains the current play time in seconds
    setPlayedSeconds(progress.playedSeconds);
    setPlayed(parseFloat(progress.played));
  };

  return {
    ready,
    playing,
    played,
    seeking,
    playedSeconds,
    handleSeekMouseDown,
    seekTo,
    playVideo,
    pauseVideo,
    onClickPlay,
    onVideoReady,
    handleSeekChange,
    handleSeekMouseUp,
    handleProgress,
    initVideo,
  };
}

export default useVideo;
