/* eslint-disable jsx-a11y/no-static-element-interactions */
import Modal from "react-modal";
import { Fragment, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import useDraggableScroll from "use-draggable-scroll";
import styles from "./index.module.scss";
import ControlButton from "../../_Video/ControlButton";
import RoadmapItem from "../RoadmapItem";
import RoadmapLine from "../RoadmapIine";
import ENDING_INFO, { RoadIdArray } from "../../../constants";
import RoadmapEnding from "../RoadmapEnding";
import { VideoState, videoPlayed, playVideo } from "../../_Video/videoDuck";
import { getRouteState } from "../../../helpers";

interface Props {
  onOpen: () => void;
  onClose: () => void;
}

const TEST_MODE = false;

export const Roadmap = ({ onOpen, onClose }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [showMobileEndings, setShowMobileEndings] = useState(false);
  const [isEnding, setIsEnding] = useState(false);
  const dispatch = useDispatch();
  const videoStatus = useSelector((state: any) => state.videos);
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { onMouseDown } = useDraggableScroll(ref);

  const currentVideo = videoStatus.find(
    (v: VideoState) => v.state === "playing",
  );
  const currentVideoId = useMemo(() => currentVideo?.id, [currentVideo?.id]);

  const openModal = (endingMode?: boolean) => {
    setIsEnding(!!endingMode);
    setOpen(true);
    onOpen();
  };

  const closeModal = () => {
    setOpen(false);
    setIsEnding(false);
    onClose();
  };

  // TODO: for dummy, finished ending logic to be implemented
  const finishedEndings = videoStatus
    .filter((v: VideoState) => v.isEnding && v.played)
    .map((v: VideoState) => v.id);

  const toVideo = (videoId: string) => {
    dispatch(playVideo(videoId));
    closeModal();
  };

  const testButtons = ({ roadId }: { roadId: string }) => {
    if (!TEST_MODE) return null;
    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          top: 0,
        }}
      >
        <button
          onClick={() => {
            dispatch(playVideo(roadId));
          }}
          style={{
            background: "#FFF",
            border: "1px solid #000",
            color: "#000",
          }}
        >
          play
        </button>
        <button
          onClick={() => {
            dispatch(videoPlayed(roadId));
          }}
          style={{
            background: "#FFF",
            border: "1px solid #000",
            color: "#000",
          }}
        >
          finish
        </button>
      </div>
    );
  };

  const introPanel = () => (
    <div className={styles.roadmap__intro}>
      <h2 className={styles.roadmap__title}>故事路線圖</h2>
      <p className={styles.roadmap__desc}>
        揀選唔同情節，帶動故事走向，體驗《與HPV的距離》多重結局！
      </p>
    </div>
  );

  const endingPanel = () => (
    <div className={styles.roadmap__endingPanel}>
      <h3 className={styles.roadmap__subTitle}>
        已完成結局 <em>{`${finishedEndings.length}/${ENDING_INFO.length}`}</em>
      </h3>
      <div className={styles.roadmap__arrow}>
        <img src="/images/icons/icon-arrow.svg" alt="" />
      </div>
      <div className={styles.roadmap__endings}>
        {ENDING_INFO.map((ending) => {
          const unlocked = finishedEndings.includes(ending.id);
          return (
            <Fragment key={ending.id}>
              <RoadmapEnding title={ending.title} unlocked={unlocked} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <ControlButton
        id="open-roadmap"
        onClick={() => openModal(false)}
        icon="/images/icons/icon-roadmap.svg"
      >
        路線圖
      </ControlButton>
      <button
        id="open-roadmap-ending"
        className={styles.roadmap__endBtn}
        tabIndex={-1}
        aria-hidden
        onClick={() => openModal(true)}
      >
        其他路線
      </button>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={() => closeModal()}
        portalClassName={styles.roadmap__panel}
        overlayClassName={styles.roadmap__panelOverlay}
        className={styles.roadmap__paneContent}
      >
        <div className={styles.roadmap__panelBody}>
          <button
            onClick={() => closeModal()}
            type="button"
            className={styles.roadmap__close}
          >
            <img src="/images/icons/icon-close.svg" alt="" />
          </button>
          <div className={styles.roadmap__mobilePanel}>
            {introPanel()}
            <button
              className={styles.roadmap__mobilePanelEndingBtn}
              onClick={() => {
                setShowMobileEndings(!showMobileEndings);
              }}
            >
              {showMobileEndings ? (
                <span className={styles.roadmap__mobilePanelEndingBtnClose}>
                  <span className={styles.roadmap__arrow}>
                    <img src="/images/icons/icon-arrow.svg" alt="" />
                  </span>
                  <span className={styles.roadmap__txt}>返回</span>
                </span>
              ) : (
                <span className={styles.roadmap__mobilePanelEndingBtnOpen}>
                  {endingPanel()}
                </span>
              )}
            </button>
          </div>
          {introPanel()}
          <div
            className={clsx(
              styles.roadmap__mapContainer,
              showMobileEndings && styles.mod__isShowEndings,
            )}
            ref={ref}
            onMouseDown={onMouseDown}
          >
            <div
              className={clsx(
                styles.roadmap__mobileEndingDetail,
                showMobileEndings && styles.mod__isShow,
              )}
            >
              {endingPanel()}
            </div>
            <div className={styles.roadmap__map}>
              <img
                className={styles.roadmap__ref}
                src="/images/roadmap/img-road-ref.png"
                alt=""
              />
              <div className={styles.roadmap__mapBody}>
                {RoadIdArray.map((roadId) => {
                  const id = `roadmap-${roadId}`;
                  const status = videoStatus.find(
                    (item: VideoState) => item.id === roadId,
                  ).state;
                  const routeStatus = getRouteState(status);
                  return (
                    <Fragment key={id}>
                      <div
                        className={clsx(
                          styles.roadmap__mapLine,
                          styles[`mod__${roadId}`],
                        )}
                      >
                        <RoadmapLine roadmapId={roadId} status={routeStatus} />
                      </div>
                      <div
                        className={clsx(
                          styles.roadmap__mapItem,
                          styles[`mod__${roadId}`],
                        )}
                      >
                        <RoadmapItem
                          isEnding={isEnding}
                          onClick={() => {
                            if (currentVideoId === roadId) {
                              toVideo("0");
                              setTimeout(() => {
                                toVideo(roadId);
                              }, 50);
                            } else {
                              toVideo(roadId);
                            }
                          }}
                          roadmapId={roadId}
                          status={status}
                        />
                        {testButtons({ roadId })}
                      </div>
                    </Fragment>
                  );
                })}
                <div className={clsx(styles.roadmap__mapItem, styles.mod__0)}>
                  <RoadmapItem
                    isEnding={isEnding}
                    onClick={() => toVideo("0")}
                    roadmapId="0"
                  />
                  {testButtons({ roadId: "0" })}
                </div>
              </div>
            </div>
          </div>
          {endingPanel()}
        </div>
      </Modal>
    </>
  );
};

export default Roadmap;
