// Initinal Video Informations
export const INITAL_VIDEO_INFOS = [
  {
    id: "0",
    next: ["1", "2"],
    optionStartAt: 0.785,
    optionBaseImage: "/images/video0/img-base.jpg",
    optionImage1: "/images/video0/img-hazel.svg",
    optionImage2: "/images/video0/img-mandy.svg",
    src: "/videos/Part01_Generic.mp4",
    imgTag: "/images/roadmap/route-0/video01_header.svg",
    // easterEggs: [
    //   {
    //     id: "v0e4",
    //     eid: "4",
    //     startAt: 0.59087,
    //     endAt: 0.61825,
    //   },
    // ],
  },
  {
    id: "1",
    next: ["11", "12"],
    optionStartAt: 0.925,
    optionBaseImage: "/images/video1/img-base.jpg",
    // optionImage1: "/images/video1/img-option1.svg",
    // optionImage2: "/images/video1/img-option2.svg",
    parents: ["0"],
    src: "/videos/Part02_Generic.mp4",
    imgTag: "/images/roadmap/route-1/video02_header.svg",
    easterEggs: [
      {
        id: "v1e41",
        eid: "4",
        startAt: 0.0186175,
        endAt: 0.0330048,
      },
      {
        id: "v1e42",
        eid: "4",
        startAt: 0.05266681,
        endAt: 0.06327407,
      },
      {
        id: "v1e43",
        eid: "4",
        startAt: 0.08712,
        endAt: 0.10208,
      },
    ],
  },
  {
    id: "2",
    next: ["21", "22"],
    optionStartAt: 0.841117,
    optionBaseImage: "/images/video2/img-base.jpg",
    optionImage1: "/images/video2/img-option1.svg",
    optionImage2: "/images/video2/img-option2.svg",
    parents: ["0"],
    src: "/videos/Part05_Generic.mp4",
    imgTag: "/images/roadmap/route-2/video05_header.svg",
    easterEggs: [
      {
        id: "v2e41",
        eid: "4",
        startAt: 0.0186175,
        endAt: 0.0330048,
      },
      {
        id: "v2e21",
        eid: "2",
        startAt: 0.36373,
        endAt: 0.459017,
      },
    ],
  },
  {
    id: "11",
    parents: ["0", "1"],
    src: "/videos/Part03_Generic.mp4",
    ending: "天生<br />桃花王",
    imgTag: "/images/roadmap/route-11/video03_header.svg",
  },
  {
    id: "12",
    parents: ["0", "1"],
    src: "/videos/Part04_Generic.mp4",
    ending: "專一暖男",
    imgTag: "/images/roadmap/route-12/video04_header.svg",
  },
  {
    id: "21",
    next: ["211", "212"],
    optionStartAt: 0.95721,
    optionBaseImage: "/images/video21/img-base.jpg",
    optionImage1: "/images/video21/img-option1.svg",
    optionImage2: "/images/video21/img-option2.svg",
    optionHoverImage1: "/images/video21/img-option1_hover.svg",
    optionHoverImage2: "/images/video21/img-option2_hover.svg",
    src: "/videos/Part09_Generic.mp4",
    imgTag: "/images/roadmap/route-21/video09_header.svg",
    parents: ["0", "2"],
    easterEggs: [
      {
        id: "v21e11",
        eid: "1",
        startAt: 0.0001,
        endAt: 0.0050065,
      },
      {
        id: "v21e32",
        eid: "32",
        startAt: 0.517612,
        endAt: 0.5223173,
      },
    ],
  },
  {
    id: "22",
    next: ["221", "222"],
    optionStartAt: 0.93527,
    optionBaseImage: "/images/video22/img-base.jpg",
    optionImage1: "/images/video22/img-option1.svg",
    optionImage2: "/images/video22/img-option2.svg",
    src: "/videos/Part06_Generic.mp4",
    imgTag: "/images/roadmap/route-22/video06_header.svg",
    parents: ["0", "2"],
    easterEggs: [
      {
        id: "v22e31",
        eid: "3",
        startAt: 0.052448,
        endAt: 0.103229,
      },
      {
        id: "v22e32",
        eid: "3",
        startAt: 0.163394,
        endAt: 0.218905,
      },
      {
        id: "v22e33",
        eid: "3",
        startAt: 0.234625,
        endAt: 0.251395,
      },
      {
        id: "v22e34",
        eid: "3",
        startAt: 0.255815,
        endAt: 0.2762677,
      },
    ],
  },
  {
    id: "211",
    parents: ["0", "2", "21"],
    src: "/videos/Part10_Generic.mp4",
    imgTag: "/images/roadmap/route-211/video10_header.svg",
    ending: "壯烈<br />犧牲的兵",
  },
  {
    id: "212",
    parents: ["0", "2", "21"],
    src: "/videos/Part11_Generic.mp4",
    imgTag: "/images/roadmap/route-212/video11_header.svg",
    ending: "沉船型<br />超高危人物",
  },
  {
    id: "221",
    parents: ["0", "2", "22"],
    src: "/videos/Part08_Generic.mp4",
    imgTag: "/images/roadmap/route-221/video08_header.svg",
    ending: "單身的典範",
  },
  {
    id: "222",
    parents: ["0", "2", "22"],
    src: "/videos/Part07_Generic.mp4",
    imgTag: "/images/roadmap/route-222/video07_header.svg",
    ending: "First Love<br />風險型",
    easterEggs: [
      {
        id: "v222e51",
        eid: "5",
        startAt: 0.16283,
        endAt: 0.186166,
      },
    ],
  },
];

// For mapping videos in roadmap
export const RoadIdArray = INITAL_VIDEO_INFOS.map((v) => v.id);

export type RoadIdType = (typeof RoadIdArray)[number];

// Status for user interaction progress for each video
export type RoadMapStatusType =
  | "ignore"
  | "locked"
  | "played"
  | "played-current-path"
  | "playing";

// Status for the line drawn in the roadmap
export type RoadMapLineStatusType = "played" | "locked" | "current";

// Possible ending information in the roadmap
export type EndingInfoType = {
  id: string;
  title: string;
};
export type EasterEggInfo = {
  id: string;
  eid: string;
  startAt: number;
  endAt: number;
};

export const ENDING_INFO: EndingInfoType[] = INITAL_VIDEO_INFOS.flatMap((v) =>
  v.ending ? [{ id: v.id, title: v.ending }] : [],
);

export default ENDING_INFO;
