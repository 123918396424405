/* eslint-disable react/no-unknown-property */
import * as THREE from "three";
import clsx from "clsx";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";

interface WavyImagesProps {
  direction?: "left" | "right";
  textures: THREE.Texture[];
}

const WavyImagesMesh = React.memo(
  ({ direction, textures }: WavyImagesProps) => {
    const [texIndex, setTexIndex] = useState(0);
    const myMesh = useRef();
    const period = 1.2; // The duration of a single wave cycle in seconds
    const { size, viewport } = useThree();
    const imgSize = textures[0].image
      ? new THREE.Vector2(textures[0].image.width, textures[0].image.height)
      : new THREE.Vector2(1, 1);
    const endImgIndex = textures.length - 1;
    const isEnded = texIndex + 1 > endImgIndex;
    const texIndex1 = texIndex;
    const texIndex2 = isEnded ? endImgIndex : texIndex + 1;

    const shaderMaterial = useMemo(() => {
      return new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          uTexture: {
            value: textures[texIndex1],
          },
          uTextureNext: {
            value: textures[texIndex2],
          },
          transition: { value: 0.0 },
          resolution: { value: new THREE.Vector2(size.width, size.height) },
          imgResolution: { value: imgSize },
          direction: { value: direction === "right" ? -1.0 : 1.0 },
          ended: { value: isEnded ? 1.0 : 0.0 },
        },
        vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
        fragmentShader: `
      uniform float time;
      uniform sampler2D uTexture;
      uniform sampler2D uTextureNext;
      uniform float transition;
      uniform float direction;
      uniform float ended;
      uniform vec2 resolution; // The resolution of the canvas
      uniform vec2 imgResolution; // The resolution of the image
      varying vec2 vUv;
      #define PI 3.1415926535897932384626433832795
      void main() {
        vec2 uv1 = vUv;
    
        // Adjust the UVs based on the aspect ratio of the image and the canvas
        float aspectRatio = resolution.x / resolution.y;
        float imgAspectRatio = imgResolution.x / imgResolution.y;
        if (aspectRatio < imgAspectRatio) {
          uv1.x = uv1.x * aspectRatio / imgAspectRatio + (1.0 - aspectRatio / imgAspectRatio) * 0.5;
        } else {
          uv1.y = uv1.y * imgAspectRatio / aspectRatio + (1.0 - imgAspectRatio / aspectRatio) * 0.5;
        }
    
        float phase = time / 0.6; // The phase of the wave, ranging from 0 to 1
        float amplitude = 0.3 * exp(-3.0 * phase); // Exponentially decaying amplitude
        if (ended < 2.0) {
          uv1.y += sin(direction * uv1.x * 5.0 + phase * 2.0 * PI) * amplitude;
        }
    
        vec4 texColor = texture2D(uTexture, uv1);
        vec4 texColorNext = texture2D(uTextureNext, uv1);
        float transitionPhase = clamp(time / (2.0 * 0.2), 0.0, 1.0); // Transition in the first 20% of the period
    
        // Calculate the transition
        float transitionLowerBound = 0.0;
        float transitionUpperBound = 1.0 - transitionPhase;
        float transitionInput = direction > 0.0 ? uv1.x : 1.0 - uv1.x;
        float transitionPosition = smoothstep(transitionLowerBound, transitionUpperBound, transitionInput);
        if (direction < 0.0) {
          transitionPosition = smoothstep(transitionLowerBound, transitionUpperBound, transitionInput);
        }
        
        // Flip transitionPosition for direction = -1.0
        transitionPosition = direction > 0.0 ? transitionPosition : 1.0 - transitionPosition;
    
        // Mix the colors
        gl_FragColor = texColorNext;
      }
    `,
      });
    }, [texIndex1, texIndex2, viewport.width, viewport.height]);

    useFrame(({ clock }) => {
      const mesh = myMesh?.current as any;
      const clockTime = clock.getElapsedTime();
      const cycle = Math.floor(clockTime / period);

      if (cycle !== texIndex && cycle < textures.length) {
        setTexIndex(cycle);
      }

      if (mesh && shaderMaterial) {
        // Update the time uniform in the shader
        shaderMaterial.uniforms.time.value = clockTime - cycle * period;
      }
    });

    if (!shaderMaterial) {
      return null;
    }

    return (
      <mesh ref={myMesh as any}>
        <planeGeometry args={[viewport.width, viewport.height, 15, 15]} />
        <primitive object={shaderMaterial} attach="material" />
      </mesh>
    );
  },
);

interface Props {
  direction?: "left" | "right";
  animated?: boolean;
  imgSrcs?: string[];
}

export const WavyImage = ({
  direction = "left",
  animated,
  imgSrcs = ["/images/landing/img-landing-bg-11.jpg"],
}: Props) => {
  const textures = useLoader(THREE.TextureLoader, imgSrcs);
  if (!animated) return null;
  return (
    <div
      className={clsx(
        styles.wavyImage,
        animated && styles.mod__animated,
        !imgSrcs && styles.mod__introImg,
      )}
    >
      <Canvas gl={{ antialias: true, toneMapping: undefined }} linear>
        <WavyImagesMesh textures={textures} direction={direction} />
      </Canvas>
    </div>
  );
};
export default React.memo(WavyImage);
