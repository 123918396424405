import clsx from "clsx";
import styles from "./index.module.scss";
import useImagePreload from "../../hooks/useImagePreload";

interface Props {
  children: React.ReactNode;
}

export const Page = ({ children }: Props) => {
  // Use bash command below command to list all files under images:
  // find . -type f \( -iname \*.jpg -o -iname \*.png -o -iname \*.gif -o -iname \*.bmp -o -iname \*.svg -o -iname \*.webp -o -iname \*.heic \)
  const { imageLoaded, allReady, progress } = useImagePreload(
    [
      "/images/landing/img-landing-bg-32.jpg",
      "/images/landing/img-landing-bg-31.jpg",
      "/images/landing/img-landing-btn.png",
      "/images/landing/img-landing-bg-21.jpg",
      "/images/landing/img-landing-bg-22.jpg",
      "/images/landing/img-landing-bg-51.jpg",
      "/images/landing/img-landing-bg-52.jpg",
      "/images/landing/img-landing-bg-42.jpg",
      "/images/landing/img-landing-bg-41.jpg",
      "/images/landing/img-kt.png",
      "/images/landing/img-landing-bg-12.jpg",
      "/images/landing/img-landing-title.png",
      "/images/landing/img-landing-bg-11.jpg",
      "/images/landing/img-landing-bg-00.jpg",
      "/images/video2/img-base.jpg",
      "/images/video2/img-option1.svg",
      "/images/video2/img-option2.svg",
      "/images/roadmap/route-221/video08_ignore.svg",
      "/images/roadmap/route-221/video08_played.svg",
      "/images/roadmap/route-221/video08_playing.svg",
      "/images/roadmap/route-221/video08_header.svg",
      "/images/roadmap/route-221/video08_played-current-path.svg",
      "/images/roadmap/route-221/video08_locked.svg",
      "/images/roadmap/route-211/video10_playing.svg",
      "/images/roadmap/route-211/video10_played.svg",
      "/images/roadmap/route-211/video10_locked.svg",
      "/images/roadmap/route-211/video10_header.svg",
      "/images/roadmap/route-211/video10_ignore.svg",
      "/images/roadmap/route-211/video10_played-current-path.svg",
      "/images/roadmap/route-0/video01.svg",
      "/images/roadmap/img-road-ref.png",
      "/images/roadmap/route-1/video02_ignore.svg",
      "/images/roadmap/route-1/video02_played-current-path.svg",
      "/images/roadmap/route-1/video02_header.svg",
      "/images/roadmap/route-1/video02_locked.svg",
      "/images/roadmap/route-1/video02_playing.svg",
      "/images/roadmap/route-1/video02_played.svg",
      "/images/roadmap/route-11/video03_header.svg",
      "/images/roadmap/route-11/video03_locked.svg",
      "/images/roadmap/route-11/video03_played.svg",
      "/images/roadmap/route-11/video03_playing.svg",
      "/images/roadmap/route-11/video03_ignore.svg",
      "/images/roadmap/route-11/video03_played-current-path.svg",
      "/images/roadmap/route-21/video09_played.svg",
      "/images/roadmap/route-21/video09_playing.svg",
      "/images/roadmap/route-21/video09_header.svg",
      "/images/roadmap/route-21/video09_played-current-path.svg",
      "/images/roadmap/route-21/video09_locked.svg",
      "/images/roadmap/route-21/video09_ignore.svg",
      "/images/roadmap/route-222/video07_ignore.svg",
      "/images/roadmap/route-222/video07_locked.svg",
      "/images/roadmap/route-222/video07_header.svg",
      "/images/roadmap/route-222/video07_played-current-path.svg",
      "/images/roadmap/route-222/video07_playing.svg",
      "/images/roadmap/route-222/video07_played.svg",
      "/images/roadmap/route-212/video11_played-current-path.svg",
      "/images/roadmap/route-212/video11_ignore.svg",
      "/images/roadmap/route-212/video11_playing.svg",
      "/images/roadmap/route-212/video11_played.svg",
      "/images/roadmap/route-212/video11_locked.svg",
      "/images/roadmap/route-212/video11_header.svg",
      "/images/roadmap/route-2/video05_played-current-path.svg",
      "/images/roadmap/route-2/video05_playing.svg",
      "/images/roadmap/route-2/video05_ignore.svg",
      "/images/roadmap/route-2/video05_played.svg",
      "/images/roadmap/route-2/video05_locked.svg",
      "/images/roadmap/route-2/video05_header.svg",
      "/images/roadmap/route-12/video04_played.svg",
      "/images/roadmap/route-12/video04_locked.svg",
      "/images/roadmap/route-12/video04_header.svg",
      "/images/roadmap/route-12/video04_playing.svg",
      "/images/roadmap/route-12/video04_played-current-path.svg",
      "/images/roadmap/route-12/video04_ignore.svg",
      "/images/roadmap/lines/locked/path_10_locked.svg",
      "/images/roadmap/lines/locked/path_01_locked.svg",
      "/images/roadmap/lines/locked/path_04_locked.svg",
      "/images/roadmap/lines/locked/path_09_locked.svg",
      "/images/roadmap/lines/locked/path_03_locked.svg",
      "/images/roadmap/lines/locked/path_06_locked.svg",
      "/images/roadmap/lines/locked/path_05_locked.svg",
      "/images/roadmap/lines/locked/path_08_locked.svg",
      "/images/roadmap/lines/locked/path_07_locked.svg",
      "/images/roadmap/lines/locked/path_02_locked.svg",
      "/images/roadmap/lines/played/path_06_played.svg",
      "/images/roadmap/lines/played/path_03_played.svg",
      "/images/roadmap/lines/played/path_09_played.svg",
      "/images/roadmap/lines/played/path_04_played.svg",
      "/images/roadmap/lines/played/path_01_played.svg",
      "/images/roadmap/lines/played/path_10_played.svg",
      "/images/roadmap/lines/played/path_02_played.svg",
      "/images/roadmap/lines/played/path_07_played.svg",
      "/images/roadmap/lines/played/path_08_played.svg",
      "/images/roadmap/lines/played/path_05_played.svg",
      "/images/roadmap/lines/current/path_07_current.svg",
      "/images/roadmap/lines/current/path_02_current.svg",
      "/images/roadmap/lines/current/path_01_current.svg",
      "/images/roadmap/lines/current/path_08_current.svg",
      "/images/roadmap/lines/current/path_04_current.svg",
      "/images/roadmap/lines/current/path_03_current.svg",
      "/images/roadmap/lines/current/path_06_current.svg",
      "/images/roadmap/lines/current/path_05_current.svg",
      "/images/roadmap/lines/current/path_10_current.svg",
      "/images/roadmap/lines/current/path_09_current.svg",
      "/images/roadmap/route-22/video06_locked.svg",
      "/images/roadmap/route-22/video06_header.svg",
      "/images/roadmap/route-22/video06_playing.svg",
      "/images/roadmap/route-22/video06_played.svg",
      "/images/roadmap/route-22/video06_played-current-path.svg",
      "/images/roadmap/route-22/video06_ignore.svg",
      "/images/test_clip_edited.svg",
      "/images/video22/img-base.jpg",
      "/images/video22/img-option1.svg",
      "/images/video22/img-option2.svg",
      "/images/easter-egg/indicator/img-indicator-2.svg",
      "/images/easter-egg/indicator/img-indicator-3.svg",
      "/images/easter-egg/indicator/img-indicator-1.svg",
      "/images/easter-egg/ee-1/img-ee-2.jpg",
      "/images/easter-egg/ee-1/img-ee-1.jpg",
      "/images/easter-egg/ee-4/img-ee-2.jpg",
      "/images/easter-egg/ee-4/img-ee-1.jpg",
      "/images/easter-egg/ee-4/img-ee-3.svg",
      "/images/easter-egg/ee-3/img-ee-2.jpg",
      "/images/easter-egg/ee-3/img-ee-1.jpg",
      "/images/easter-egg/ee-3/img-ee-3.svg",
      "/images/easter-egg/ee-2/img-ee-2.jpg",
      "/images/easter-egg/ee-2/img-ee-1.jpg",
      "/images/easter-egg/ee-2/img-ee-3.svg",
      "/images/easter-egg/ee-5/img-ee-2.jpg",
      "/images/easter-egg/ee-5/img-ee-1.jpg",
      "/images/easter-egg/ee-5/img-ee-3.svg",
      "/images/video0/img-tag.svg",
      "/images/video0/img-base.jpg",
      "/images/video0/img-hazel.svg",
      "/images/video0/img-mandy.svg",
      "/images/common/img-progess-pattern.png",
      "/images/video1/video_shape_ref.svg",
      "/images/video1/img-base.jpg",
      "/images/video1/img-option1.svg",
      "/images/video1/img-option2.svg",
      "/images/test_clip.svg",
      "/images/ending/ending-221/img-bg.jpg",
      "/images/ending/ending-221/img-title.svg",
      "/images/ending/ending-221/img-text.svg",
      "/images/ending/ending-221/img-kv.png",
      "/images/ending/ending-211/img-bg.jpg",
      "/images/ending/ending-211/img-title.svg",
      "/images/ending/ending-211/img-text.svg",
      "/images/ending/ending-211/img-kv.png",
      "/images/ending/img-replay-text.svg",
      "/images/ending/img-bg.jpg",
      "/images/ending/ending-11/img-bg.jpg",
      "/images/ending/ending-11/img-title.svg",
      "/images/ending/ending-11/img-text.svg",
      "/images/ending/ending-11/img-kv.png",
      "/images/ending/img-readmore-text.svg",
      "/images/ending/img-readmore-text-bg.svg",
      "/images/ending/img-roadmap-icon.svg",
      "/images/ending/img-readmore-icon.svg",
      "/images/ending/ending-222/img-bg.jpg",
      "/images/ending/ending-222/img-title.svg",
      "/images/ending/ending-222/img-text.svg",
      "/images/ending/ending-222/img-kv.png",
      "/images/ending/img-roadmap-text.svg",
      "/images/ending/ending-212/img-bg.jpg",
      "/images/ending/ending-212/img-title.svg",
      "/images/ending/ending-212/img-text.svg",
      "/images/ending/ending-212/img-kv.png",
      "/images/ending/img-readmore-icon-bg.svg",
      "/images/ending/ending-12/img-bg.jpg",
      "/images/ending/ending-12/img-title.svg",
      "/images/ending/ending-12/img-text.svg",
      "/images/ending/ending-12/img-kv.png",
      "/images/ending/img-readmore-ref.png",
      "/images/ending/img-replay-icon.svg",
      "/images/icons/icon-lock.svg",
      "/images/icons/icon-roadmap.svg",
      "/images/icons/icon-pause.svg",
      "/images/icons/icon-tick.svg",
      "/images/icons/icon-close.svg",
      "/images/icons/icon-start-button/img-start-button-icon-bg.svg",
      "/images/icons/icon-start-button/img-start-button-text.svg",
      "/images/icons/icon-start-button/img-start-button-bg.svg",
      "/images/icons/icon-start-button/img-start-button-icon.svg",
      "/images/icons/icon-play.svg",
      "/images/video21/img-option2_hover.svg",
      "/images/video21/img-base.jpg",
      "/images/video21/img-option1.svg",
      "/images/video21/img-option2.svg",
      "/images/video21/img-option1_hover.svg",
    ],
    250,
  );
  return (
    <div
      className={clsx(
        styles.page,
        imageLoaded && styles.mod__imageReady,
        allReady && styles.mod__ready,
      )}
    >
      {allReady && <div className={styles.page__child}>{children}</div>}
      <div className={styles.page__loadingCovers}>
        <div
          className={styles.page__loadingCover}
          style={{ transform: `translateX(-${progress}%)` }}
        />
        <div
          className={styles.page__loadingCover}
          style={{ transform: `translateX(${progress}%)` }}
        />
      </div>
      <div className={styles.page__portrait}>
        為了獲得最佳體驗，請使用橫向模式
      </div>
    </div>
  );
};

export default Page;
