import clsx from "clsx";
import styles from "./index.module.scss";
import AnimatedButton from "../AnimatedButton";

interface Props {
  animated?: boolean;
  onClick?: () => void;
}

export const LandingPanel = ({ animated, onClick = () => null }: Props) => {
  return (
    <div
      className={clsx(styles.landingPanel, animated && styles.mod__animated)}
    >
      <div className={styles.landingPanel__title}>
        <img src="/images/landing/img-landing-title.png" alt="" />
      </div>
      <div className={styles.landingPanel__desc}>
        一個周旋於選擇、多重結局嘅愛情互動故事。
        <br />
        由觀眾控制，用自選劇情，搵出HPV同你嘅最終距離。
      </div>
      <div className={styles.landingPanel__btn}>
        <AnimatedButton animated={animated} onClick={() => onClick()} />
      </div>
    </div>
  );
};

export default LandingPanel;
