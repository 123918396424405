import React from "react";
import "./assets/styles/index.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { isMobile } from "mobile-device-detect";
import Playground from "./pages/Playground";
import Landing from "./pages/Landing";
import Page from "./features/Page";
import { store } from "./redux/store";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div className="App" data-mobile-device={isMobile}>
          <Routes>
            <Route
              path="/"
              element={
                <Page>
                  <Landing />
                </Page>
              }
            />
            <Route path="/playground" element={<Playground />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
