import clsx from "clsx";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import EndingButton from "../EndingButton";
import ENDING_INFO from "../../../constants";
import { VideoState } from "../../_Video/videoDuck";

interface Props {
  id?: string;
  animated?: boolean;
  openRoadmap?: () => void;
}

export const EndingPanel = ({
  id = "11",
  animated,
  openRoadmap = () => null,
}: Props) => {
  const videoStatus = useSelector((state: any) => state.videos);
  const finishedEndings = videoStatus
    .filter((v: VideoState) => v.isEnding && v.played)
    .map((v: VideoState) => v.id);
  return (
    <div
      className={clsx(
        styles.endingPanel,
        animated && styles.mod__animated,
        id && styles[`mod__ending${id}`],
      )}
    >
      <div className={styles.endingPanel__title}>
        <img src={`/images/ending/ending-${id}/img-title.svg`} alt="" />
      </div>
      <div className={styles.endingPanel__desc}>
        <img src={`/images/ending/ending-${id}/img-text.svg`} alt="" />
      </div>
      <div className={styles.endingPanel__btn}>
        {finishedEndings.length >= ENDING_INFO.length ? (
          <>
            <div className={styles.endingPanel__btn1}>
              <EndingButton
                type="readmore"
                animated={animated}
                onClick={() => {
                  window.open("https://www.hpv.com.hk/male/", "_blank");
                }}
              />
            </div>
            <div className={styles.endingPanel__btn2}>
              <EndingButton
                type="replay"
                animated={animated}
                onClick={() => {
                  openRoadmap();
                  // dispatch(reset());
                  // dispatch(playVideo("0"));
                }}
              />
            </div>
          </>
        ) : (
          <EndingButton animated={animated} onClick={() => openRoadmap()} />
        )}
      </div>
    </div>
  );
};

export default EndingPanel;
