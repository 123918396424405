import {
  RoadIdType,
  RoadMapStatusType,
  RoadMapLineStatusType,
} from "../constants";

export const formatSeconds = (seconds: number) => {
  // const hours = Math.floor(seconds / 3600);
  const newSec = seconds % 3600;
  const minutes = Math.floor(newSec / 60);
  const sec = Math.floor(newSec % 60);

  return [minutes, sec].map((v) => (v < 10 ? `0${v}` : v)).join(":");
};

export const getRoadmap = (
  routeId: RoadIdType,
  status: RoadMapStatusType,
): string => {
  const basedPath = "/images/roadmap/";
  const filePrefixMap: { [key: string]: string } = {
    "1": "video02",
    "2": "video05",
    "11": "video03",
    "12": "video04",
    "21": "video09",
    "22": "video06",
    "211": "video10",
    "212": "video11",
    "221": "video08",
    "222": "video07",
  };

  return `${basedPath}route-${routeId}/${filePrefixMap[routeId]}_${status}.svg`;
};

export const getRoadmapLine = (
  routeId: RoadIdType,
  status: RoadMapLineStatusType,
): string => {
  const basedPath = "/images/roadmap/lines/";
  const filePrefixMap: { [key: string]: string } = {
    "1": "01",
    "2": "02",
    "11": "03",
    "12": "04",
    "21": "05",
    "22": "06",
    "211": "07",
    "212": "08",
    "221": "09",
    "222": "10",
  };
  if (!filePrefixMap[routeId]) return "";

  return `${basedPath}${status}/path_${filePrefixMap[routeId]}_${status}.svg`;
};

export const getRouteState = (
  status: RoadMapStatusType,
): RoadMapLineStatusType => {
  const routeStatusMap: { [key: string]: RoadMapLineStatusType } = {
    ignore: "played",
    locked: "locked",
    played: "played",
    "played-current-path": "current",
    playing: "current",
  };
  return routeStatusMap[status];
};
export default formatSeconds;
