import clsx from "clsx";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import ENDING_INFO from "../../../constants";
import { VideoState } from "../../_Video/videoDuck";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  animated?: boolean;
  type?: "roadmap" | "readmore" | "replay";
  // TODO: make icons, text, etc. to be passed in as props
}

const IMG_SRC: any = {
  roadmap: {
    IMG_BTN_BG: "/images/icons/icon-start-button/img-start-button-bg.svg",
    IMG_ICON_BG: "/images/icons/icon-start-button/img-start-button-icon-bg.svg",
    IMG_ICON: "/images/ending/img-roadmap-icon.svg",
    IMG_TXT: "/images/ending/img-roadmap-text.svg",
    IMG_REF: "/images/landing/img-landing-btn.png",
  },
  readmore: {
    IMG_BTN_BG: "/images/ending/img-readmore-text-bg.svg",
    IMG_ICON_BG: "/images/ending/img-readmore-icon-bg.svg",
    IMG_ICON: "/images/ending/img-readmore-icon.svg",
    IMG_TXT: "/images/ending/img-readmore-text.svg",
    IMG_REF: "/images/landing/img-landing-btn.png",
  },
  replay: {
    IMG_BTN_BG: "/images/icons/icon-start-button/img-start-button-bg.svg",
    IMG_ICON_BG: "/images/icons/icon-start-button/img-start-button-icon-bg.svg",
    IMG_ICON: "/images/ending/img-replay-icon.svg",
    IMG_TXT: "/images/ending/img-replay-text.svg",
    IMG_REF: "/images/landing/img-landing-btn.png",
  },
};

export const EndingButton = ({
  children,
  animated,
  type = "roadmap",
  ...props
}: Props) => {
  const { IMG_BTN_BG, IMG_ICON_BG, IMG_ICON, IMG_TXT, IMG_REF } = IMG_SRC[type];
  const videoStatus = useSelector((state: any) => state.videos);
  const totalEndings = ENDING_INFO.length;
  const finishedEndings = videoStatus
    .filter((v: VideoState) => v.isEnding && v.played)
    .map((v: VideoState) => v.id);
  const remainingEndings = totalEndings - finishedEndings.length;

  return (
    <button
      {...props}
      className={clsx(
        styles.animatedButton,
        animated && styles.mod__animated,
        type && styles[`mod__${type}`],
        props.className,
      )}
    >
      <div className={clsx(styles.animatedButton__body)}>
        <div className={clsx(styles.animatedButton__icon)}>
          <img
            className={styles.animatedButton__iconBg}
            src={IMG_ICON_BG}
            alt=""
          />
          <img
            className={styles.animatedButton__iconPlay}
            src={IMG_ICON}
            alt=""
          />
        </div>
        <div className={clsx(styles.animatedButton__textBody)}>
          <div className={clsx(styles.animatedButton__textBodyInner)}>
            <img
              className={styles.animatedButton__textBg}
              src={IMG_BTN_BG}
              alt=""
            />
            <img className={styles.animatedButton__text} src={IMG_TXT} alt="" />
            {children && (
              <div className={styles.animatedButton__chidren}>{children}</div>
            )}
          </div>
        </div>
        {type === "roadmap" && (
          <div
            className={styles.animatedButton__endingCount}
          >{`剩餘 ${remainingEndings} 個結局`}</div>
        )}
      </div>
      <img className={styles.animatedButton__ref} src={IMG_REF} alt="" />
    </button>
  );
};

export default EndingButton;
