import { useMemo } from "react";
import styles from "./index.module.scss";

interface Props {
  title?: string;
  unlocked?: boolean;
}

export const RoadmapEnding = ({ unlocked, title = "Unknown" }: Props) => {
  const titleDisplay = useMemo(() => {
    if (unlocked) {
      return title;
    }
    return "???";
  }, [title, unlocked]);

  return (
    <div className={styles.roadmapEnding}>
      <img
        src={
          unlocked
            ? "/images/icons/icon-tick.svg"
            : "/images/icons/icon-lock.svg"
        }
        className={styles.roadmapEnding__icon}
        alt=""
      />
      <div
        className={styles.roadmapEnding__title}
        dangerouslySetInnerHTML={{
          __html: titleDisplay,
        }}
      />
    </div>
  );
};

export default RoadmapEnding;
