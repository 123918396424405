import clsx from "clsx";
import styles from "./index.module.scss";

interface Props {
  animated?: boolean;
}

export const LandingKt = ({ animated }: Props) => {
  return (
    <div className={clsx(styles.landingKt, animated && styles.mod__animated)}>
      <img
        className={clsx(styles.landingKt__img)}
        src="/images/landing/img-kt.png"
        alt=""
      />
    </div>
  );
};

export default LandingKt;
