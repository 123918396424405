import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./index.module.scss";
import VideoPlayer from "../VideoPlayer";
import { VideoState, playVideo } from "../videoDuck";
import { INITAL_VIDEO_INFOS } from "../../../constants";
import { RootState } from "../../../redux/store";

interface Props {
  parentReady?: boolean;
}

export const VideoContainers = ({ parentReady }: Props) => {
  const dispatch = useDispatch();
  const videoStatus = useSelector((state: RootState) => state.videos);
  const currentVideo = videoStatus.find(
    (v: VideoState) => v.state === "playing",
  );
  // Prevent rerendering when videoStatus changes
  const currentVideoId = useMemo(() => currentVideo?.id, [currentVideo?.id]);
  const currentVideoInfo = useMemo(
    () => INITAL_VIDEO_INFOS?.find((v) => v.id === currentVideoId),
    [currentVideoId],
  );
  const [cleaningUp, setCleaningUp] = useState(true);

  // Handle video transition
  const [videoLoading, setVideoLoading] = useState(true);
  const allReady = parentReady && !videoLoading;
  useEffect(() => {
    if (typeof currentVideoId === "undefined") dispatch(playVideo("0"));
    setVideoLoading(true);
  }, [currentVideoId]);

  useEffect(() => {
    setCleaningUp(true);
    setTimeout(() => {
      setCleaningUp(false);
    }, 50);
  }, [currentVideoInfo?.src]);

  const onReady = () => {
    setVideoLoading(false);
  };

  const goToNextVideo = (target: string) => {
    dispatch(playVideo(target));
  };

  return (
    <div
      className={clsx(styles.videoContainers, allReady && styles.mod__allReady)}
    >
      {/* TODO: Remove testing code */}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          zIndex: 100,
          pointerEvents: "none",
        }}
        hidden
      >{`-${currentVideoId} - ${parentReady} | ${videoLoading} - ${JSON.stringify(
        currentVideo,
      )}`}</div>
      <div className={clsx(styles.videoContainers__body)}>
        {!cleaningUp && (
          <VideoPlayer
            parentReady={allReady}
            onReady={onReady}
            videoId={currentVideoId}
            videoUrl={currentVideoInfo?.src}
            imgTag={currentVideoInfo?.imgTag}
            easterEggs={currentVideoInfo?.easterEggs}
            isEnding={!!currentVideoInfo?.ending}
            optionStartAt={currentVideoInfo?.optionStartAt}
            options={!!currentVideoInfo?.next}
            onEnded={(option) => {
              if (option) {
                const nextVideo = currentVideoInfo?.next?.[option - 1] || "0";
                goToNextVideo(`${nextVideo}`);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VideoContainers;
