import ReactPlayer from "react-player";
import clsx from "clsx";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./index.module.scss";
import ControlButton from "../ControlButton";
import { formatSeconds } from "../../../helpers";
import Roadmap from "../../_Roadmap/Roadmap";
import useVideo from "../../../hooks/useVideo";
import { videoPlayed } from "../videoDuck";
import VideoOptions from "../VideoOptions";
import EasterEgg from "../../_EasterEgg/EasterEgg";
import { EasterEggInfo } from "../../../constants";
import Ending from "../../_Ending/Ending";

interface Props {
  parentReady?: boolean;
  videoUrl?: string;
  videoId?: string;
  optionStartAt?: number;
  imgTag?: string;
  options?: boolean;
  isEnding?: boolean;
  easterEggs?: EasterEggInfo[];
  onReady?: () => void;
  onEnded?: (option?: number) => void;
}

const DEFAULT_VIDEO_URL = "/videos/Part01_Generic.mp4";
const DEFAULT_IMG_TAG = "/images/video0/img-tag.svg";

export const VideoPlayer = React.memo(
  ({
    parentReady,
    videoId = "0",
    videoUrl = DEFAULT_VIDEO_URL,
    optionStartAt,
    imgTag = DEFAULT_IMG_TAG,
    isEnding,
    options,
    easterEggs,
    onReady = () => {},
    onEnded = () => {},
  }: Props) => {
    const playerRef = useRef(null);
    const {
      ready,
      playing,
      played,
      seeking,
      playedSeconds,
      handleSeekMouseDown,
      playVideo,
      pauseVideo,
      onClickPlay,
      onVideoReady,
      handleSeekChange,
      handleSeekMouseUp,
      handleProgress,
      initVideo,
    } = useVideo(playerRef);

    const [showEnding, setShowEnding] = useState(false);
    const [option, setOption] = useState<1 | 2 | undefined>();
    const dispatch = useDispatch();

    useEffect(() => {
      setOption(undefined);
      setShowEnding(false);
      initVideo();
    }, [videoUrl]);

    // Auto Play
    useEffect(() => {
      if (ready && parentReady) {
        playVideo();
        console.log("url change playVideo");
        setTimeout(() => {
          playVideo();
        }, 150);
      }
    }, [ready, parentReady]);

    // Options
    const selectOption = (targetOption: 1 | 2) => {
      if (!options) return;
      setOption(targetOption);
    };

    // Show Hide Controls

    const [isControlVisible, setControlVisible] = useState(true);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseMove = () => {
      setControlVisible(true);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setControlVisible(false);
      }, 3000);
    };

    useEffect(() => {
      return () => {
        // cleanup on unmount
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }, []);

    useEffect(() => {
      if (option) {
        dispatch(videoPlayed(videoId));
        onEnded(option);
      }
    }, [option]);

    const optionStart = optionStartAt || 0;
    const optionActive = useMemo(() => {
      if (!options) return 0;
      const fadeInStart = optionStart;
      const fadeInDur = 0.004;
      const fadeInEndPoint = fadeInStart + fadeInDur;
      if (played >= fadeInStart && played <= fadeInEndPoint) {
        return (played - fadeInStart) / (fadeInEndPoint - fadeInStart);
      }
      if (played > fadeInEndPoint) {
        return 1;
      }
      return 0;
    }, [played]);

    return (
      <div
        className={clsx(
          styles.videoPlayer,
          videoId && styles[`mod__video${videoId}`],
          isControlVisible && styles.mod__showControl,
        )}
        onMouseMove={handleMouseMove}
      >
        <div
          className={styles.videoPlayer__debug}
          hidden={window.location.hostname !== "localhost"}
        >
          {`Ready: ${ready} | parentReady: ${parentReady} | playing: ${playing} | played: ${played} | seeking: ${seeking} | easterEggs: ${JSON.stringify(
            easterEggs,
          )}`}
        </div>
        <div className={styles.videoPlayer__header}>
          <img className={styles.videoPlayer__tag} src={imgTag} alt="" />
        </div>
        <div className={styles.videoPlayer__easterEgg}>
          {easterEggs?.map((item) => {
            return (
              <Fragment key={`easter-egg-${item.id}`}>
                <EasterEgg
                  videoId={videoId}
                  id={item.id}
                  eid={item.eid}
                  show={played >= item.startAt && played <= item.endAt}
                  onOpen={() => {
                    pauseVideo();
                  }}
                  onClose={() => {
                    playVideo();
                  }}
                />
              </Fragment>
            );
          })}
        </div>
        {options && (
          <VideoOptions
            id={videoId}
            option={option}
            optionActive={optionActive}
            selectOption={selectOption}
          />
        )}
        <button
          onClick={() => {
            if (playing) {
              pauseVideo();
            } else {
              playVideo();
            }
          }}
          className={styles.videoPlayer__clickHandler}
        >
          {playing ? "play" : "pause"}
        </button>
        <ReactPlayer
          ref={playerRef}
          playsinline
          width="100%"
          height="100%"
          className={styles.videoPlayer__play}
          playing={playing && !seeking && parentReady}
          url={videoUrl}
          progressInterval={100}
          onProgress={handleProgress}
          onReady={() => {
            onReady();
            onVideoReady();
          }}
          onEnded={() => {
            if (isEnding) {
              dispatch(videoPlayed(videoId));
              setShowEnding(true);
            }
          }}
        />
        <div className={styles.videoPlayer__control}>
          <div className={styles.videoPlayer__controlBody}>
            <Roadmap
              onOpen={() => {
                pauseVideo();
              }}
              onClose={() => {
                if ((isEnding && showEnding) || played >= 1) return;
                playVideo();
              }}
            />
            <ControlButton
              onClick={() => onClickPlay()}
              icon={
                playing
                  ? "/images/icons/icon-pause.svg"
                  : "/images/icons/icon-play.svg"
              }
            />
            <div
              className={clsx(
                styles.progress,
                seeking && styles.mod__seeking,
                !playing && styles.mod__paused,
              )}
            >
              <div className={styles.progress__bar}>
                <input
                  className={styles.progress__handle}
                  type="range"
                  min={0}
                  max={0.999999}
                  step="any"
                  value={played}
                  onMouseDown={handleSeekMouseDown}
                  onChange={handleSeekChange}
                  onMouseUp={handleSeekMouseUp}
                />
                <div
                  className={styles.progress__barProgress}
                  style={{
                    width: `${played * 100}%`,
                    backgroundImage: `url('/images/common/img-progess-pattern.png')`,
                  }}
                />
              </div>
              <div className={styles.progress__time}>
                {formatSeconds(playedSeconds)}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.videoPlayer__ending}>
          {isEnding && (
            <Ending
              id={videoId}
              show={showEnding}
              openRoadmap={() => {
                document.getElementById("open-roadmap-ending")?.click();
              }}
            />
          )}
        </div>
      </div>
    );
  },
);

export default VideoPlayer;
