import Modal from "react-modal";
import React, { Fragment, useState } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import EasterEggIndicator from "../EasterEggIndicator";

interface Props {
  id?: string;
  eid?: string;
  videoId?: string;
  show: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const EasterEgg = React.memo(
  ({ id = "4", eid = "4", videoId = "0", onOpen, onClose, show }: Props) => {
    const [isOpen, setOpen] = useState(false);

    const openModal = () => {
      setOpen(true);
      onOpen();
    };

    const closeModal = () => {
      setOpen(false);
      onClose();
    };

    if (!show) return null;

    return (
      <>
        <button
          className={clsx(
            styles.easterEgg__btn,
            eid && styles[`mod__eid${eid}`],
            id && styles[`mod__id${eid}`],
          )}
          data-id={id}
          onClick={() => openModal()}
        >
          <span className={styles.easterEgg__label}>EASTER EGG {id}</span>
          <div className={styles.easterEgg__indicator}>
            <EasterEggIndicator />
          </div>
        </button>
        <Modal
          isOpen={isOpen}
          ariaHideApp={false}
          onRequestClose={() => closeModal()}
          portalClassName={clsx(
            styles.easterEgg__panel,
            styles.easterEgg,
            videoId && styles[`mod__video${videoId}`],
            id && styles[`mod__eid${eid}`],
          )}
          overlayClassName={styles.easterEgg__panelOverlay}
          className={styles.easterEgg__paneContent}
        >
          <button
            onClick={() => closeModal()}
            className={styles.easterEgg__closeOverlay}
          >
            回到故事
          </button>
          <div className={styles.easterEgg__panelBody}>
            {eid === "1" && (
              <div className={styles.easterEgg__images}>
                <img
                  className={styles.easterEgg__image1}
                  src="/images/easter-egg/ee-1/img-ee-1.jpg"
                  alt=""
                />
                <div className={styles.easterEgg__imageGroup}>
                  <img
                    className={styles.easterEgg__image2}
                    src="/images/easter-egg/ee-1/img-ee-2.jpg"
                    alt=""
                  />
                </div>
              </div>
            )}
            {eid === "2" && (
              <div className={styles.easterEgg__images}>
                <img
                  className={styles.easterEgg__image1}
                  src="/images/easter-egg/ee-2/img-ee-1.jpg"
                  alt=""
                />
                <div className={styles.easterEgg__imageGroup}>
                  <img
                    className={styles.easterEgg__image2}
                    src="/images/easter-egg/ee-2/img-ee-2.jpg"
                    alt=""
                  />
                  <img
                    className={styles.easterEgg__image3}
                    src="/images/easter-egg/ee-2/img-ee-3.svg"
                    alt=""
                  />
                </div>
              </div>
            )}
            {eid === "32" && (
              <div className={styles.easterEgg__images}>
                <div className={styles.easterEgg__imageGroup}>
                  <img
                    className={styles.easterEgg__image2}
                    src="/images/easter-egg/ee-3/img-ee-2.jpg"
                    alt=""
                  />
                  <img
                    className={styles.easterEgg__image1}
                    src="/images/easter-egg/ee-3/img-ee-1.jpg"
                    alt=""
                  />
                </div>
                <img
                  className={styles.easterEgg__image3}
                  src="/images/easter-egg/ee-3/img-ee-3.svg"
                  alt=""
                />
              </div>
            )}
            {eid === "3" && (
              <div className={styles.easterEgg__images}>
                <div className={styles.easterEgg__imageGroup}>
                  <img
                    className={styles.easterEgg__image2}
                    src="/images/easter-egg/ee-3/img-ee-2.jpg"
                    alt=""
                  />
                  <img
                    className={styles.easterEgg__image1}
                    src="/images/easter-egg/ee-3/img-ee-1.jpg"
                    alt=""
                  />
                  <img
                    className={styles.easterEgg__image3}
                    src="/images/easter-egg/ee-3/img-ee-3.svg"
                    alt=""
                  />
                </div>
              </div>
            )}
            {eid === "4" && (
              <div className={styles.easterEgg__images}>
                <img
                  className={styles.easterEgg__image1}
                  src="/images/easter-egg/ee-4/img-ee-1.jpg"
                  alt=""
                />
                <div className={styles.easterEgg__imageGroup}>
                  <img
                    className={styles.easterEgg__image2}
                    src="/images/easter-egg/ee-4/img-ee-2.jpg"
                    alt=""
                  />
                  <img
                    className={styles.easterEgg__image3}
                    src="/images/easter-egg/ee-4/img-ee-3.svg"
                    alt=""
                  />
                </div>
              </div>
            )}
            {eid === "5" && (
              <div className={styles.easterEgg__images}>
                <div className={styles.easterEgg__imageGroup}>
                  <img
                    className={styles.easterEgg__image1}
                    src="/images/easter-egg/ee-5/img-ee-1.jpg"
                    alt=""
                  />
                  <img
                    className={styles.easterEgg__image3}
                    src="/images/easter-egg/ee-5/img-ee-3.svg"
                    alt=""
                  />
                </div>
                <img
                  className={styles.easterEgg__image2}
                  src="/images/easter-egg/ee-5/img-ee-2.jpg"
                  alt=""
                />
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  },
);

export default EasterEgg;
