import { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import LandingBg from "../../features/_Landing/LandingBg";
import styles from "./index.module.scss";
import LandingKt from "../../features/_Landing/LandingKt";
import LandingPanel from "../../features/_Landing/LandingPanel";
import VideoContainers from "../../features/_Video/VideoContainer";
import { playVideo } from "../../features/_Video/videoDuck";

const ANIMATION_STEPS = [
  {
    id: "ktFadeIn",
    type: "ktFadeIn",
    // duration: 1000,
  },
  {
    id: "slideImage-1",
    type: "slideImage",
    imgSrc: {
      leftImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-11.jpg",
      ],
      rightImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-12.jpg",
      ],
    },
    duration: 700,
    slideIn: true,
  },
  {
    id: "slideImage-2",
    type: "slideImage",
    imgSrc: {
      leftImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-21.jpg",
      ],
      rightImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-22.jpg",
      ],
    },
    duration: 700,
    slideIn: true,
  },
  {
    id: "slideImage-3",
    type: "slideImage",
    imgSrc: {
      leftImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-31.jpg",
      ],
      rightImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-32.jpg",
      ],
    },
    duration: 700,
  },
  {
    id: "slideImage-4",
    type: "slideImage",
    imgSrc: {
      leftImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-41.jpg",
      ],
      rightImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-42.jpg",
      ],
    },
    duration: 700,
    slideIn: true,
  },
  {
    id: "slideImage-5",
    type: "slideImage",
    imgSrc: {
      leftImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-51.jpg",
      ],
      rightImages: [
        // "/images/landing/img-landing-bg-00.jpg",
        "/images/landing/img-landing-bg-52.jpg",
      ],
    },
    duration: 700,
    slideIn: true,
  },
  {
    id: "slideImageEND",
    type: "slideImageEND",
    slideIn: true,
  },
  {
    id: "animationEND",
    type: "animationEND",
    duration: 10,
  },
];

export const Landing = () => {
  const [screenMode, setScreenMode] = useState<"initial" | "toVideo" | "video">(
    "initial",
  );
  const [step, setStep] = useState(-1);
  // TODO: remove after testing
  const dispatch = useDispatch();

  useEffect(() => {
    if (step < ANIMATION_STEPS.length) {
      let duration = ANIMATION_STEPS?.[step]?.duration || 700;
      if (step < 0) duration = 600;
      const timer = setTimeout(() => {
        setStep(step + 1);
      }, duration);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [step]);

  useEffect(() => {
    let Timer: ReturnType<typeof setTimeout>;
    if (screenMode === "toVideo") {
      Timer = setTimeout(() => {
        setScreenMode("video");
      }, 2000);
    }
    return () => {
      clearTimeout(Timer);
    };
  }, [screenMode]);

  const aniStart = step >= 0;
  const aniEnd = step >= ANIMATION_STEPS.length;

  return (
    <div
      className={clsx(
        styles.landing,
        aniEnd && styles.mod__animationEnd,
        styles[`mod__${screenMode}`],
      )}
    >
      <div className={styles.landing__bg}>
        {ANIMATION_STEPS?.map((item, index) => {
          if (item.type !== "slideImage" && item.type !== "slideImageEND")
            return null;
          const finished = item.type === "slideImage" && step > index + 1;
          return (
            <Fragment key={`ani-${item.id}`}>
              <LandingBg
                animated={step >= index}
                finished={finished}
                slideIn={item.slideIn}
                imgSrc={item.imgSrc}
              />
            </Fragment>
          );
        })}
      </div>
      <div className={styles.landing__main}>
        <div className={styles.landing__mainBody}>
          <div className={styles.landing__kt}>
            <div className={styles.landing__ktBody}>
              <LandingKt animated={aniStart && screenMode === "initial"} />
            </div>
          </div>
          <div className={styles.landing__content}>
            <LandingPanel
              animated={aniEnd && screenMode === "initial"}
              onClick={() => setScreenMode("toVideo")}
            />
          </div>
        </div>
      </div>
      <div className={styles.landing__video}>
        {window.location.hostname === "localhost" && (
          <button
            onClick={() => {
              dispatch(playVideo("0"));
              setScreenMode("initial");
            }}
            style={{ position: "fixed", background: "#FFF", zIndex: 10000 }}
          >
            BACK
          </button>
        )}
        <VideoContainers parentReady={screenMode === "video"} />
      </div>
    </div>
  );
};

export default Landing;
