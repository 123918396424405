import React, { useEffect } from "react";
import clsx from "clsx";
import styles from "./index.module.scss";

interface Props {
  id: string;
  show?: boolean;
}

export const EndingBg = React.memo(({ id, show }: Props) => {
  const [kvAnimated, setKvAnimated] = React.useState(false);

  const triggerBgAnimation = () => {
    // Get the iframe element
    const iframe: any = document.getElementById("ending-effect-iframe");

    // Post a message to the iframe
    if (iframe) {
      iframe.contentWindow.postMessage("changeImage", "*");
    }
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setKvAnimated(true);
      }, 250);
      setTimeout(() => {
        triggerBgAnimation();
      }, 1000);
    } else {
      setKvAnimated(false);
    }
  }, [show]);

  return (
    <div
      className={clsx(
        styles.endingBg,
        styles[`mod__${id}`],
        kvAnimated && styles.mod__animated,
      )}
    >
      <button
        className={clsx(styles.endingBg__btn)}
        onClick={() => triggerBgAnimation()}
        hidden={window.location.hostname !== "localhost"}
      >
        Trigger Animation {id}
      </button>
      {/* <Iframe
        url={`/effect/${id}.html`}
        width="100%"
        height="100%"
        id="ending-effect-iframe"
        className={clsx(styles.endingBg__iframe)}
        display="block"
        position="relative"
      /> */}
      <img
        src={`/images/ending/ending-${id}/img-bg.jpg`}
        alt=""
        className={clsx(styles.endingBg__bg)}
      />
    </div>
  );
});

export default EndingBg;
