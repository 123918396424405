import { useState, useRef } from "react";
import clsx from "clsx";
import { getRoadmap } from "../../../helpers";
import styles from "./index.module.scss";
import { RoadIdType, RoadMapStatusType } from "../../../constants";
import EasterEggIndicator from "../../_EasterEgg/EasterEggIndicator";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  roadmapId?: RoadIdType | "0";
  isEnding?: boolean;
  status?: RoadMapStatusType;
}

export const RoadmapItem = ({
  roadmapId = "1",
  isEnding = false,
  status = "playing",
  ...otherProps
}: Props) => {
  const imgRef = useRef(null);
  const [imageWidth, setImageWidth] = useState<number | undefined>();
  const imageSrc =
    roadmapId === "0"
      ? "/images/roadmap/route-0/video01.svg"
      : getRoadmap(roadmapId, status);

  return (
    <button
      {...otherProps}
      className={clsx(styles.roadmapItem, styles[`mod__${status}`])}
      disabled={!isEnding && window.location.hostname !== "localhost"}
    >
      {isEnding && (
        <div className={styles.roadmapItem__indicator}>
          <EasterEggIndicator />
        </div>
      )}
      <img
        ref={imgRef}
        src={imageSrc}
        className={styles.roadmapItem__img}
        style={{
          width: imageWidth ? `${(imageWidth / 180) * 100}%` : undefined,
        }}
        alt=""
        onLoad={() => {
          if (imgRef?.current) {
            const imgElem = imgRef?.current as HTMLImageElement;
            setImageWidth(imgElem?.naturalWidth);
          }
        }}
      />
    </button>
  );
};

export default RoadmapItem;
