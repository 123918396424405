import Roadmap from "../../features/_Roadmap/Roadmap";
import WavyImage from "../../features/_Landing/WavyImage";
import styles from "./index.module.scss";
import Ending from "../../features/_Ending/Ending";
import EasterEgg from "../../features/_EasterEgg/EasterEgg";

export const Playground = () => {
  return (
    <>
      <div className={styles.playground__ending}>
        <Ending id="222" show />
        <EasterEgg
          videoId="test"
          id="test"
          eid="32"
          show
          onOpen={() => {}}
          onClose={() => {}}
        />
      </div>
      <div className={styles.playground}>
        <Roadmap onOpen={() => null} onClose={() => null} />
        <div className={styles.playground__half}>
          <WavyImage
            imgSrcs={[
              "/images/landing/img-landing-bg-00.jpg",
              "/images/landing/img-landing-bg-11.jpg",
              "/images/landing/img-landing-bg-21.jpg",
              "/images/landing/img-landing-bg-31.jpg",
            ]}
            direction="left"
            animated
          />
        </div>
        <div className={styles.playground__half}>
          <WavyImage
            imgSrcs={[
              "/images/landing/img-landing-bg-00.jpg",
              "/images/landing/img-landing-bg-12.jpg",
              "/images/landing/img-landing-bg-22.jpg",
              "/images/landing/img-landing-bg-32.jpg",
            ]}
            direction="right"
            animated
          />
        </div>
      </div>
    </>
  );
};

export default Playground;
